import React, { Component } from "react";
import { graphql } from "gatsby"
import { FormattedMessage } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PostLink from "../components/postLink";

class TagTemplate extends Component {
  render() {
    const tag = this.props.data.wordpress.tag
    const posts = tag.posts

    return (
      <Layout>
        <SEO title={tag.name} />
        <div className="post pad-t-6">
          <div className="container">
            <h1 className="post__title has-text-centered mar-b-6">
              <span class="post__title__sub">
                <FormattedMessage id="blog.tag" />
              </span>
              <br />
              {tag.name}
            </h1>

            <div class="postList columns is-centered is-multiline">
              {posts.nodes.map(post => (
                <PostLink props={post} />
              ))}
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default TagTemplate;

export const pageQuery = graphql`
  query($id: ID!) {
    wordpress {
      tag(id: $id) {
        name
        posts(first: 1000) {
          nodes {
            id
            title
            slug
            date
            author {
              name
              slug
              UserCustom {
                avatar {
                  sourceUrl(size: CRP_THUMBNAIL)
                }
              }
            }
            featuredImage {
              altText
              sourceUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 524, quality: 80) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
